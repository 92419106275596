<template>
  <div class="mb-3">
    <PostForm @posted="$store.dispatch('getFeed')" v-if="$store.state.user.currentUser.id"
              placeholder="Поделитесь своими событиями, успехами, фотография или может быть проблемами."/>
    <div v-if="!feed.wasLoaded">
      <PostStub class="mt-2"/>
      <PostStub class="mt-2"/>
      <PostStub class="mt-2"/>
      <PostStub class="mt-2"/>
    </div>

    <Post @posted="$store.dispatch('getFeed')" show-last-comment @wasDeleted="$store.dispatch('getFeed')" show-user
          show-project class="mt-2" v-for="post in feed.posts.slice(0, 1)"
          :key="post.id" :post="post"/>
    <TelegramBanner style="margin: 1em 0;"/>
    <Post @posted="$store.dispatch('getFeed')" show-last-comment @wasDeleted="$store.dispatch('getFeed')" show-user
          show-project class="mt-2" v-for="post in feed.posts.slice(1)"
          :key="post.id" :post="post"/>


  </div>
</template>

<script>
import PostForm from "@/components/Feed/PostForm";
import Post from "@/components/Feed/Post";
import PostStub from "@/components/Feed/PostStub";
import TelegramBanner from "@/components/TelegramBanner";

export default {
  name: "Feed",
  components: {TelegramBanner, PostStub, Post, PostForm},
  computed: {
    feed() {
      return this.$store.state.feed;
    }
  },
  data() {
    return {
      selectedPost: null
    }
  },
  mounted() {
    this.$store.dispatch('getFeed');
  }
}
</script>

<style scoped>


</style>
