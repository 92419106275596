<template>
  <div class="d-flex flex-column flex-md-row justify-lg-center justify-start "
       style="background: #e3e3e3; padding: 20px; width: 100%; border-radius: 20px;">
    <div class="pa-0 pl-md-5">
      <p>Вступай в&nbsp;Telegram-сообщество, общайся с&nbsp;единомышленниками, читай полезный контент, задавай вопросы,
        участвуй в&nbsp;развитии АИС и&nbsp;будь в&nbsp;курсе всех обновлений</p>
      <a class="anim" target="_blank"
         style="position: relative; background-color: #039be5; color: white; text-decoration: none; padding: 10px; border-radius: 10px;  padding-left: 2.5em;"
         href="https://t.me/+4FfyUcJYWwkxMjEy">
        <img src="/telegram.svg"
             style="width: 2em; position:absolute; left: 0;  top: 50%; transform: translate(0.5em, -50%);">
        Вступить в сообщество
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelegramBanner"
}
</script>

<style scoped>
.anim {
  background-image: linear-gradient(270deg, rgba(100, 181, 239, 0) 48.44%, #64b5ef 75.52%, rgba(100, 181, 239, 0) 100%);
  background-repeat: no-repeat;
  animation: bg-move linear 5s infinite;
}

@-webkit-keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes bg-move {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
