<template>
  <div class="post">
    <div class="flare"/>
    <div class="post__header d-flex justify-center align-center">
      <div class="d-flex justify-center align-center">
        <v-chip link>
          <v-avatar left>
            <div class="stub-avatar"/>
          </v-avatar>
          <span class="post__title">
            <span class="stub-word" style="width: 70px"></span>
          </span>

        </v-chip>

      </div>
      <v-spacer/>
      <div class="post__date">
        <span class="stub-word" style="width: 70px">
        </span>
      </div>


    </div>
    <div>
      <div class="pa-4">
        <span class="stub-word" :style="`width: calc(10% + ${(Math.random()*100)}px)`"  v-for="i in textWordCount" :key="i"></span>
      </div>

      <div v-if="hasPicture" class="stub-img">
      </div>
      <div class="post_footer">
        <v-btn :disabled="true" rounded text color="primary" class="mt-2">
          <div class="stub-symbol"/>
          <v-icon right>mdi-thumb-up-outline</v-icon>
        </v-btn>

        <v-btn disabled rounded text color="primary" class="mt-2">
          <div class="stub-symbol"/>
          <v-icon right>mdi-comment-outline</v-icon>
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PostStub",
  data(){
    return {
      textWordCount: 0,
      hasPicture: false
    }
  },
  mounted() {
    this.textWordCount = 5 + Math.round(Math.random()*10);
    this.hasPicture = Math.random() > 0.3;
  }
}
</script>

<style scoped>
.post {
  background: white;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  position: relative;
  overflow: hidden;
}


.post__header {
  padding: 10px 20px;
  display: flex;
}

.post_footer {
  display: flex;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
}

.post_img__prev {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  z-index: 2;
  top: 50%;
  transform: translateY(-70%);
}

.post_img__next {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  z-index: 2;
  top: 50%;
  transform: translateY(-70%);
}

.post__img {
  position: relative;
}

.post__files {
  padding: 0 20px;
}

.post__date {
  font-size: 14px;
  font-weight: 300;
}

.post__title {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
}

.file__link {
  text-decoration: none;
  z-index: 20;
  display: block;
}

.stub-img {
  background: #d3d3d3;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}

.stub-word {
  padding: 5px;
  background: #d3d3d3;
  width: 64px;
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.stub-symbol {
  padding: 5px;
  background: #d3d3d3;
  overflow: hidden;
  width: 2em;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.stub-avatar {
  width: 30px;
  height: 30px;
  background: #d3d3d3;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.flare {
  display: block;
  position: absolute;
  width: 15%;
  height: 200%;
  margin-left: 50px;
  background: #fff;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.7) 50%,
      rgba(255, 255, 255, 0.5) 100%
  );
  left: -100%;
  top: -50%;
  transform: rotate(35deg);
  z-index: 1;
  opacity: 0.5;
  animation: movingFlare 3s ease-in-out 0.05s infinite;
}

@keyframes movingFlare {
  0% {
    left: -100%;
    opacity: 0.5;
  }
  100% {
    left: 100%;
    opacity: 0.2;
  }
}
</style>
