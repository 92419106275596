<template>
  <v-container style="max-width: 980px; margin: 0 auto;">
    <v-row>
      <v-col class="pa-0 pa-md-2 mt-3 mb-3" cols="12" lg="12">
        <PersonalWidget v-if="this.$store.state.user.currentUser.id > 0"/>
      </v-col>
      <v-col class="pa-0 pa-md-2" cols="12" lg="7">
        <Feed/>
      </v-col>
      <v-col class="pa-0 pa-md-2" lg="5">
        <div style="position: sticky; top: 25px;">
          <ProjectListItemComponent class="mb-3" v-for="project in $store.state.projects.projects.data.slice(0, 8)"
                                    :key="project.id"
                                    :project="project"/>
        </div>

      </v-col>
    </v-row>


  </v-container>
</template>

<script>

import ProjectListItemComponent from "@/components/Projects/ProjectListItemComponent.vue";
import analytics from "@/api/analytics.js";
import Feed from "@/components/Feed/Feed";
import PersonalWidget from "@/components/PersonalWidget";

export default {
  name: "Homepage",
  components: {
    PersonalWidget,
    Feed,
    ProjectListItemComponent,
  },
  data() {
    return {
      dialog: false,
      notice: 1,
      loading: true
    }
  },
  computed: {
    events() {
      return this.$store.state.events.events;
    }
  },
  mounted() {
    this.$store.commit('setEvents', []);
    Promise.all([
      this.$store.dispatch('getLastEvents'),
      this.$store.dispatch('getProjects')
    ]).then(() => {
      this.loading = false;
      analytics.openHomePageEvent(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)
    })

  }
}
</script>

<style scoped>
.card_carousel {
}
</style>
