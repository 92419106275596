<template>
  <CardUI class="hoverable" @click.native="$router.push('/projects/'+project.id)">
    <template  v-slot:footer>
      <UserLinkComponent :text="$moment.utc(project.created_at).local().fromNow()" :user="project.responsible_user"/>
      <v-spacer/>
    </template>
    <template v-slot:body>
      <div style="position: relative"
          v-if="$vuetify.breakpoint.smAndDown && project.logotype_url !== 'https://sun9-66.userapi.com/c858520/v858520688/d5bf7/6XE6nux89fU.jpg' && project.logotype_url !== 'https://surgu.fra1.digitaloceanspaces.com/common/no_photo.jpeg'">
        <div :style="`background-image: url('${project.logotype_url}'); width: 100%; height: 100%; position: absolute; filter: blur(20px); opacity: 0.5`"></div>
        <v-img :src="project.logotype_url" contain class="mb-3" height="200" width="100%"></v-img>
      </div>
      <div class="project_card__title">{{ project.title.trim() }}</div>
      <div v-if="participants.length" class="d-flex my-2">
        <v-tooltip :key="p.id" v-for="p in participants" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs"
                      v-on="on" class="mr-2" size="24">
              <v-img  :lazy-src="p.avatar_16" :src="p.avatar"/>
            </v-avatar>
          </template>
          <span>{{ p.name }}</span>
        </v-tooltip>

      </div>
      <div class="mb-1">
        <v-chip class="mr-2" v-if="project.pool" :color="color(project.pool.id)" x-small dark>{{ project.pool.name }}</v-chip>
        <v-chip class="mr-2" v-if="!project.approved && !project.decline_reason" color="warning" x-small dark>Требуется модерация</v-chip>
        <v-chip class="mr-2" v-if="!project.approved && project.decline_reason" color="red" x-small dark>Отклонен</v-chip>
        <v-chip class="mr-2" v-if="project.archive_reason" color="red" x-small dark>В архиве</v-chip>
        <v-chip class="mr-2" v-if="project.type" color="primary" x-small dark>{{ project.type.name }} проект</v-chip>
      </div>
      <div class="project_card__content">
        <div class="project_card__task">
          {{ project.problem.trim() }} <br/>
          {{ project.task.trim() }} <br/>
          {{ project.result.trim() }}
        </div>
        <v-spacer/>
        <div
            v-if="$vuetify.breakpoint.mdAndUp && project.logotype_url !== 'https://sun9-66.userapi.com/c858520/v858520688/d5bf7/6XE6nux89fU.jpg' && project.logotype_url !== 'https://surgu.fra1.digitaloceanspaces.com/common/no_photo.jpeg'"
            class="project_card__logo">
          <v-img :src="project.logotype_url" aspect-ratio="1" height="100" width="100"></v-img>
        </div>
      </div>
      <div v-if="offers.filter((o) =>  o.quota > o.users.length).length" class="mt-4">
        Вакансии: <br/>
        {{ offers.filter((o) => o.quota > o.users.length).map((o) => o.name.charAt(0).toUpperCase() + o.name.slice(1)).join(' · ') }}
      </div>
    </template>
  </CardUI>
</template>

<script>
import analytics from "@/api/analytics.js";
import CardUI from "@/components/UI/CardUI.vue";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";

export default {
  name: "ProjectListItemComponent",
  components: {UserLinkComponent, CardUI},
  props: ['project'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    offers() {
      return this.project.roles;
    },
    participants() {
      return this.project.roles ? this.project.roles.map(r => r.users).reduce((p, c) => [...p, c[0]]).filter((p) => !!p) : [];
    }
  },
  methods: {
    color(pool_id){

      const i = pool_id % 6;
      // eslint-disable-next-line no-console
      console.log(pool_id, i)
      const colors = [
          '#003841',
          '#53377a',
          '#4d5645',
          '#1b5583',
          '#b82e5c',
          '#434b1b',
          '#1d1e33',
      ];
      return colors[i]
    },
    approve(project) {
      this.loading = true;
      if (confirm('Вы действительно хотите одобрить?')) {
        this.$store.dispatch('approveProject', {id: project.id}).then(() => {
          this.$store.dispatch('getProjects');
          this.loading = false;
          this.$store.dispatch('getProjectsByUser', {})
        })
      }
    },
    remove(project) {
      if (confirm('Вы действительно хотите удалить проект?')) {
        this.loading = true;
        this.$store.dispatch('removeProject', {id: project.id}).then(() => {
          this.loading = false;
          this.$store.dispatch('getProjects');
        })

      }
    }
  },
  mounted() {
    if (this.$store.getters.isAuth) {
      analytics.event({event: 'PROJECT_WAS_SEEN', details: {project: this.project}})
    }
  }
}
</script>

<style scoped>
.feature {
  background: #b75d19;
  position: absolute;
  width: 100px;
  bottom: 10px;
  right: 10px;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.project_card__date {
  font-weight: 300;
  font-size: 13px;
}

.project_card__header {
  font-size: 15px;
}

.project_card__header {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.project_card__title {
  font-weight: 400;
  display: flex;
  font-size: 20px !important;
  align-items: center;
}

.project_card__task {
  white-space: pre-wrap;
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  margin-right: 10px;
}


.project_card__content {
  display: flex;
  align-items: center;
}

.project_card__author {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project_card__header {
  overflow: hidden;
}

.project_card__logo {
  padding: 10px;
}

.project_card__offers {
  display: flex;

}

.project_card__offers-wrap {
  flex: 1;
  display: flex;
  overflow: auto;
}

.project_card__offers-wrap::-webkit-scrollbar {
  display: none;
}

.project_card__hire {
}

.project_card__hire-title {
  border-top: 1px solid #efefef;

}

.hoverable:hover {
  cursor: pointer;
  opacity: 0.9;
}
</style>
