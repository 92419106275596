<template>
  <div  :style="`opacity: ${opacity};`" class="personal-widget" v-if="user.id > 0">
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div :style="`${colors[0]}; transform: scale(${getScale(opacity)});`"
             class="swiper-slide">
          <h3><img alt="hand" style="width: 64px; margin-right: 0.2em" src="/icons/wave_hand.svg"/><br/>
            <span v-html="getDayPart()"/>,
            {{ user.first_name }}</h3>
        </div>

        <div @click="() => {
            $router.push('projects')
          }" v-if="banner && banner.type === 'error'" :style="`cursor:pointer; background: #ff6961; transform: scale(${getScale(opacity)});`"
             class="swiper-slide">
          <h2>⚠️ ОПД</h2>
          <p style="font-size: 15px; -webkit-line-clamp: 5;  display: -webkit-box !important; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
            {{ banner.text }}</p>
        </div>

        <div  v-for="(project, i) in projects" :key="project.id" :style="`${colors[2 + i]}; transform: scale(${getScale(opacity)});`"
             class="swiper-slide">
          <div v-if="project.logotype_url !== 'https://surgu.fra1.digitaloceanspaces.com/common/no_photo.jpeg'"
              :style="`mix-blend-mode: multiply; overflow: hidden; background: url(${project.logotype_url}); left: 0px; top: 0px;  filter: blur(1px); position: absolute; width: 100%; height: 100%; background-size: cover; opacity: 0.4`"></div>
          <span style="color: #efefef; font-weight: 300; z-index: 1">ваш проект</span>
          <h3 class="two-lines" style="z-index: 1">{{ project.title }}</h3>
          <div style="z-index: 1" class="mt-4">
            <v-btn :href="`#/projects/${project.id}`" rounded x-small outlined color="white">Перейти к проекту</v-btn>
          </div>
        </div>

        <div :style="`${colors[1]}; transform: scale(${getScale(opacity)});`"
             class="swiper-slide">
          <h2>Интерфейс</h2>
          <p style="font-size: 15px;" class="mt-1">Мы существенно переработали интерфейс, сделав его более
            приятным.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import projects from "@/api/projects";
import banners from "@/api/banners";

export default {
  name: "PersonalWidget",
  computed: {
    user() {
      return this.$store.state.user.currentUser
    }
  },
  data() {
    return {
      projects: [],
      banner: null,
      swiper: null,
      opacity: 1,
      colors: []
    }
  },
  methods: {
    getScale(value){
       return 1 - ((1- value) * 0.1)
    },
    getColor() {
      return "#000000".replace(/0/g, function () {
        return (~~(Math.random() * 16)).toString(16);
      });
    },
    getGradient() {
      const variants = [
        'background-color: #4158D0;\n' +
        'background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);\n',
        "background-color: #4158D0;\n" +
        "background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);\n",
        'background-color: #4158D0;\n' +
        'background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);\n',
        'background-color: #4158D0;\n' +
        'background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);\n',
        'background-color: #FBAB7E;\n' +
        'background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);\n',
        'background-color: #21D4FD;\n' +
        'background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);\n',
        'background-color: #21D4FD;\n' +
        'background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);\n',
        'background-color: #21D4FD;\n' +
        'background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);\n',
        'background-color: #FA8BFF;\n' +
        'background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);\n',
        'background-color: #FA8BFF;\n' +
        'background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);\n',
        'background-color: #FA8BFF;\n' +
        'background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);\n',
        'background-color: #FF3CAC;\n' +
        'background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);\n'
      ]

      const n = Math.floor(Math.random() * variants.length);
      // eslint-disable-next-line no-console
      console.log(n);
      return variants[n];
    },
    getDayPart() {
      const h = this.$moment().hour();
      if (h > 6 && h < 11) {
        return "Доброе&nbsp;утро"
      }
      if (h >= 11 && h < 16) {
        return 'Добрый день'
      }
      if (h >= 16 && h < 23) {
        return 'Добрый вечер'
      }
      return 'Доброй ночи'
    }
  },
  beforeMount() {
    for (let i = 0; i < 100; i++) {
      this.colors[i] = this.getGradient();
    }
  },
  mounted() {
    this.opacity = 1 - (window.pageYOffset / 200).toPrecision(2);
    window.addEventListener('scroll', () => {
      this.opacity = 1 - (window.pageYOffset / 200).toPrecision(2);
    })
    if (!this.user.id) {
      return;
    }
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: this.$vuetify.breakpoint.smAndDown ? 10 : 20,
      slidesOffsetBefore: this.$vuetify.breakpoint.smAndDown ? 10 : 20,
      slidesOffsetAfter: this.$vuetify.breakpoint.smAndDown ? 10 : 20,
      grabCursor: true
    });

    banners.get('opd_banner').then((r) => {
      this.banner = r.data;
      setTimeout(() => {
        this.swiper.update();
      }, 100)
    });

    projects.auth_all({
      by_user: this.user.id,
      perPage: 4
    }).then((r) => {
      this.projects = r.data.data.slice(0, 4)
      // eslint-disable-next-line no-console
      console.log(this.swiper)
      setTimeout(() => {
        this.swiper.update();
      }, 100)

    })
  }
}
</script>

<style scoped>
.personal-widget {
  height: 185px;
  z-index: 0;
  transition: opacity 0.01s ease-in-out;
}

.swiper-container {
  max-width: 964px;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 1;
}

.swiper-slide {
  box-shadow: rgba(20, 28, 37, 0.2) 0px 1px 10px;
  width: 150px !important;
  padding: 20px;
  height: 150px;
  border-radius: 20px;
  color: white;
  display: flex;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
}

.swiper-slide#id1 {
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

}

.two-lines {
  -webkit-line-clamp: 3;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
